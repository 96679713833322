export const defaultStaringLocationEditorCoordinates = [0, 0];
export const defaultStaringLocationEditorZoomLevel = 0;
export const minZoomLevel = 0;
export const maxZoomLevel = 28;
// export const zoomLevelRange = [
// 	{
// 		level: 0,
// 		descriptor: 'The whole world',
// 	},
// 	{ level: 1 },
// 	{ level: 2 },
// 	{ level: 3 },
// 	{ level: 4 },
// 	{ level: 5, descriptor: 'The size of Western Australia' },
// 	{ level: 6 },
// 	{ level: 7 },
// 	{ level: 8 },
// 	{ level: 9 },
// 	{ level: 10, descriptor: 'The size of the Greater Perth Metropolitan Area' },
// 	{ level: 11 },
// 	{ level: 12 },
// 	{ level: 13 },
// 	{ level: 14 },
// 	{ level: 15, descriptor: 'The size of the Perth CBD' },
// 	{ level: 16 },
// 	{ level: 17 },
// 	{ level: 18 },
// 	{ level: 19 },
// 	{ level: 20, descriptor: 'Street-level' },
// 	{ level: 21 },
// 	{ level: 22 },
// 	{ level: 23 },
// 	{ level: 24 },
// 	{ level: 25, descriptor: 'Pavement-level' },
// 	{ level: 26 },
// 	{ level: 27 },
// 	{ level: 28, descriptor: "Now you're just being ridiculous" },
// ];
