import './App.css';

function NotFound() {
	return (
		<div
			style={{
				position: 'relative',
				zIndex: 30,
				backgroundColor: 'red',
				height: '10dvh',
			}}
		>
			Not Found
		</div>
	);
}

export default NotFound;
